import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import AboutHero from '../components/about-components/aboutHero'

const AboutPage = () => (
  <Layout>
    <SEO title="About" />
    <AboutHero />
  </Layout>
)

export default AboutPage
