import React from 'react'
import AboutImage from "./aboutImage"

const AboutHero = () => (
  <div className="container max">
    <div className="row py-5 align-items-center"
      style={{
        minHeight: 'calc(-228px + 100vh)'
      }}>
      <div className="col-md-5">
        <p className="display">ABOUT MASTERCARE</p>
        <h2>IN THIS LINE OF WORK, GETTING THE JOB DONE RIGHT IS CRUCIAL.</h2>
        <p>For your reputation, your business and most importantly, the comfort and productivity of your clients. Our parent company, DiversiTech, has been committed to putting the right tools in your hands for decades. And now, as MasterCare, we're delivering a complete line of HVAC/R tools that you can trust to find and fix leaks, retrofit older systems and supercharge performance. Every product we offer is made to serve you and your clients well.</p>
      </div>
      <div className="col-md-6 offset-md-1">
        <AboutImage />
      </div>
    </div>
  </div>

)
export default AboutHero